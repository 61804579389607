/* eslint-disable max-len */
import _ from 'lodash'
import React from 'react'
import './about-us-components-style.scss'
import { getUrlMedia } from '../../../helpers'
import { ImageRoundCorners } from '../../../components/frames-images'

const OurVision = ({ t, data }) => (
  <div className="blockMiddle__container">
    <div className="row blockMiddle__content">
      <div className="col-sm-7 col-12 blockMiddle__content__col">
        <ImageRoundCorners
          addon
          src={getUrlMedia(_.get(data, 'image'))}
          roundBottomLefttHoz
        />
      </div>
      <div className="col-sm-5 col-12">
        <span className="tag">{t('aboutUs.ourVision.tag')}</span>
        <h2 className="page-title">{_.get(data, 'title')}</h2>
      </div>
    </div>
  </div>
)

export default OurVision
