/* eslint-disable max-len */
import _ from 'lodash'
import React from 'react'
import { ImageRoundCorners } from '../../../components/frames-images'
import { getUrlMedia } from '../../../helpers'
import './about-us-components-style.scss'

const BlockHead = ({ sectionOne, sectionTwo }) => (
  <div className="container-xxl p-0">
    <div className="row blockHead__container">
      <div className="col-sm-7 col-12 blockHead__content">
        <h1 className="blockHead__title">{_.get(sectionOne, 'title')}</h1>
        <p>{_.get(sectionOne, 'description')}</p>
      </div>
      <div className="col-sm-5 col-12 blockHead__image">
        <ImageRoundCorners
          src={getUrlMedia(_.get(sectionOne, 'image'))}
          roundBottomRight
        />
      </div>
    </div>
    <div className="row blockHead__container down">
      <div className="col-sm-7 col-12 blockHead__image blockHead__image__down">
        <ImageRoundCorners
          src={getUrlMedia(_.get(sectionTwo, 'image'))}
          roundBottomRightHoz
          addon={true}
        />
      </div>
      <div className="col-sm-5 col-12 blockHead__content">
        <h2 className="blockHead__subtitle">{_.get(sectionTwo, 'title')}</h2>
        <div
          className="rich-text"
          dangerouslySetInnerHTML={{ __html: _.get(sectionTwo, 'description') }}
        />
      </div>
    </div>
  </div>
)

export default BlockHead
