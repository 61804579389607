import _ from 'lodash'
import React from 'react'

import { CardOutline } from '../../../components'
import './about-us-components-style.scss'

const Ourvalues = ({ t, data }) => (
  <div className="container-xxl aboutUsOurValues__container p-4">
    <div className="aboutUsOurValues__head">
      <span className="tag">{t('aboutUs.ourValues.tag')}</span>
      <h2 className="subtitle-page">{t('aboutUs.ourValues.title')}</h2>
    </div>
    <div className="row">
      {_.map(data, (item) => (
        <div className="col-sm-3 my-3" key={item.title}>
          <CardOutline title={_.get(item, 'title')}>
            <p className="cardOutline-text">{_.get(item, 'description')}</p>
          </CardOutline>
        </div>
      ))}
    </div>
  </div>
)

export default Ourvalues
