import React from 'react'
import _ from 'lodash'
import { getUrlMedia } from '../../../helpers'

const CtaAboutUs = ({ data, t }) => (
  <div
    className="ctaAboutUs__container"
    style={{
      backgroundImage: `url(${getUrlMedia(_.get(data, 'background_image'))})`,
    }}
  >
    <div className="ctaAboutUs__content">
      <span>{t('aboutUs.ourMission.tag')}</span>
      <p>{_.get(data, 'mission_text')}</p>
    </div>
  </div>
)

export default CtaAboutUs
