import React from 'react'
import _ from 'lodash'
import { getUrlMedia } from '../../../helpers'
import IconExternalLink from '../../../assets/icons/pacificcross_icons_external-link.svg'

const Card = ({ item }) => (
  <div className="col-6 col-md-3 colFrameLocations">
    <div
      className="frameLocations"
      style={{
        backgroundImage: `url(${getUrlMedia(
          _.get(item, 'location_thumbnail'),
        )})`,
      }}
    >
      <p>
        <span>{_.get(item, 'location_name')}</span>
        <a href={_.get(item, 'link')}>
          <IconExternalLink width="22" fill="#fff" />
        </a>
      </p>
    </div>
  </div>
)

const OurLocations = ({ t, data }) => (
  <div className="ourLocations__container row">
    <div className="col-12 col-md-7">
      <span className="tag">{t('aboutUs.ourLocations.tag')}</span>
      <h3 className="page-title">{_.get(data, 'title')}</h3>
    </div>
    <div className="col-12 col-md-5">
      <p className="content">{_.get(data, 'description')}</p>
    </div>
    <div className="col-12 mt-5">
      <div className="row">
        {_.map(_.get(data, 'locations_data'), (item) => (
          <Card item={item} />
        ))}
      </div>
    </div>
  </div>
)

export default OurLocations
