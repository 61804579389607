import _ from 'lodash'
import React from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { AccordionChildTemplate } from '../../../components'
import './about-us-components-style.scss'

const YearRow = ({ item }) => (
  <div className="col-12 yearRow__container">
    <p className="yearRow__year">{item.title}</p>
    <div className="yearRow__description" dangerouslySetInnerHTML={
      { __html: item.content }
    } />
    {/* <p className="yearRow__description">{item.content}</p> */}
  </div>
)

const OurMilestone = ({ i18n, t, data }) => {
  const breakpoints = useBreakpoint()
  return (
    <div className="container-xxl aboutUsOurMilestones__container">
      <div className="aboutUsOurMilestones__head">
        <span className="tag">{t('milestones.title')}</span>
        <h2 className="subtitle-page">
          {t('milestones.description')}
        </h2>
      </div>
      {breakpoints.xs ? (
        <AccordionChildTemplate t={t} i18n={i18n} data={data} />
      ) : (
        <div className="row">
          {_.map(data, (item, idx) => (
            <YearRow key={item.title + idx} item={item} />
          ))}
        </div>
      )}
    </div>
  )
}

export default OurMilestone
